.pp-detail-page .bordered__block .table td {
    font-size: 16px;
}

.pp-detail-page .bordered__block .table td h5 {
    font-size: 24px;
}

.pp-detail-page .bordered__block .table tr:last-child td {
    border: 0 !important;
}

.pp-detail-page .block-title {
    background-image: url(/images/bordered-bg-bottom.svg);
    background-repeat: no-repeat;
    background-position: bottom 5px right -30px;
    min-height: 65px;
    display: block;
}

.pp-detail-page .block-title h4 {
    background: -webkit-linear-gradient(#00bcb0, #f7d542);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 35px;
    line-height: 35px;
    font-family: 'Grand', sans-serif;
    letter-spacing: 0.03em;
    padding-bottom: 10px;
    text-transform: uppercase;
}